import CryptoJS from "crypto-js";

export const Encryption = (id) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY; // Replace with your secret key
  const encryptedId = CryptoJS.AES.encrypt(id?.toString(), secretKey)?.toString();
  const urlEncodedId = encodeURIComponent(encryptedId);
  return urlEncodedId;
};

export const Decryption = (id) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY; // Replace with your secret key
  const encryptedId = decodeURIComponent(id);
  const decryptedId = CryptoJS.AES.decrypt(encryptedId, secretKey)?.toString(
    CryptoJS.enc.Utf8
  );
  return parseInt(decryptedId);
};

// import CryptoJS from "crypto-js";

// export const Encryption = (id) => {
//   const secretKey = process.env.REACT_APP_SECRET_KEY; // Replace with your secret key
//   const encryptedId = CryptoJS.AES.encrypt(id?.toString(), secretKey);
//   const urlSafeId = CryptoJS.enc.Base64url.stringify(encryptedId.ciphertext);
//   return urlSafeId;
// };

// export const Decryption = (id) => {
//   const secretKey = process.env.REACT_APP_SECRET_KEY; // Replace with your secret key
//   const parsedId = CryptoJS.enc.Base64url.parse(id);
//   const decryptedId = CryptoJS.AES.decrypt(
//     { ciphertext: parsedId },
//     secretKey
//   )?.toString(CryptoJS.enc.Utf8);
//   return parseInt(decryptedId);
// };