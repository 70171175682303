import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { HttpService } from "../../service/HttpService";
import { displayDuration } from "../../Mixin/DisplayDuration";
import moment from "moment";

const handleDownloadPlantExcel = async (setShowLoginPopup) => {
  const httpService = new HttpService(setShowLoginPopup);

  const fetchPlantData = () => {
    return new Promise((resolve, reject) => {
      httpService
        .get(`plant/get_plant?sortBy=name&sortOrder=DESC`)
        .then((response) => {
          if (response?.data) {
            const plantData = response?.data?.data;
            resolve(plantData);
          } else {
            reject(new Error("No data found")); // Reject if no data found
          }
        })
        .catch((error) => {
          console.error("Error fetching plant data:", error);
          reject(error); // Reject with the error
        });
    });
  };

  try {
    const plantData = await fetchPlantData();

    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet
    const worksheet = workbook.addWorksheet("Plant");

    // Add headers
    worksheet.addRow([
      "Profile Image",
      "Plant Name",
      "Lifetime",
      "Activities",
      "Milestones",
      "Company",
      "Location",
      "Description",
      "Files",
      "Sequence",
      "Rooms"
    ]);
    worksheet.getRow(1).alignment = { horizontal: "center" }; // Center align the text

    if (plantData !== null && plantData !== undefined && plantData.length > 0) {
      plantData.forEach((plant) => {
        worksheet.addRow([
          plant?.plant_profile_img || "",
          plant?.name || "",
          displayDuration(plant.days, plant.hours, plant.minutes) || "",
          plant?.activities?.length
            ? plant?.activities.map((activity) => activity.name).join(", ")
            : "",
          plant?.milestones?.length
            ? plant?.milestones.map((milestone) => milestone.name).join(", ")
            : "",
          plant?.company_name || "",
          plant?.location_name || "",
          plant?.description || "",
          plant?.attachment?.length > 0 ? plant.attachment.join(", ") : "",
          plant?.sort_order || "",
          plant?.rooms?.length > 0 ? plant.rooms.join(", ") : "",
        ]);
      });
    }

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    const formattedDate = moment().format("MM-DD-YYYY_hh-mm-ss_A");

    // Save the Excel file
    saveAs(new Blob([buffer]), `PlantData_${formattedDate}.xlsx`);
  } catch (error) {
    console.error("Error downloading Excel:", error);
  }
};

export { handleDownloadPlantExcel };
