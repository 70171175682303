import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { HttpService } from "../../service/HttpService";
import dayjs from "dayjs";
import moment from "moment";

const handleDownloadScheduleExcel = async (setShowLoginPopup) => {
  const httpService = new HttpService(setShowLoginPopup);

  const fetchScheduleData = () => {
    return new Promise((resolve, reject) => {
      httpService
        .get(`schedule/get_schedule`)
        .then((response) => {
          if (response?.data) {
            const scheduleData = response?.data?.data;
            resolve(scheduleData);
          } else {
            reject(new Error("No data found")); // Reject if no data found
          }
        })
        .catch((error) => {
          console.error("Error fetching schedule data:", error);
          reject(error); // Reject with the error
        });
    });
  };

  try {
    const scheduleData = await fetchScheduleData();

    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet
    const worksheet = workbook.addWorksheet("Schedule");

    // Add headers
    worksheet.addRow([
      "Schedule Name",
      "Plant",
      "Room",
      "On-Going",
      "Start Date",
      "End Date",
    ]);
    worksheet.getRow(1).alignment = { horizontal: "center" }; // Center align the text

    if (
      scheduleData !== null &&
      scheduleData !== undefined &&
      scheduleData.length > 0
    ) {
      scheduleData.forEach((schedule) => {
        worksheet.addRow([
          schedule?.name || "",
          schedule?.plant_name || "",
          schedule?.room_name || "",
          schedule?.ongoing === true ? "On-Going" :"",
          schedule?.adjusted_start_date
            ? dayjs(schedule.adjusted_start_date).format("MMM/DD/YYYY hh:mm a")
            : "", // Formatting start date
          schedule?.adjusted_end_date
            ? dayjs(schedule.adjusted_end_date).format("MMM/DD/YYYY hh:mm a")
            : "", // Formatting end date
        ]);
      });
    }

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    const formattedDate = moment().format("MM-DD-YYYY_hh-mm-ss_A");

    // Save the Excel file
    saveAs(new Blob([buffer]), `ScheduleData_${formattedDate}.xlsx`);
  } catch (error) {
    console.error("Error downloading Excel:", error);
  }
};

export { handleDownloadScheduleExcel };
