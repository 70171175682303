import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { HttpService } from "../../service/HttpService";
import moment from "moment/moment";

const handleDownloadCompanyExcel = async (setShowLoginPopup) => {
  const httpService = new HttpService(setShowLoginPopup);

  const fetchCompanyData = () => {
    return new Promise((resolve, reject) => {
      httpService
        .get(`company/get_companies`)
        .then((response) => {
          if (response?.data) {
            const companyData = response?.data?.data;
            resolve(companyData);
          } else {
            reject(new Error("No data found")); // Reject if no data found
          }
        })
        .catch((error) => {
          console.error("Error fetching company data:", error);
          reject(error); // Reject with the error
        });
    });
  };

  try {
    const companyData = await fetchCompanyData();

    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet
    const worksheet = workbook.addWorksheet("Company");

    // Add headers
    worksheet.addRow(["Name", "Size", "Description", "Sequence", "Files"]);
    worksheet.getRow(1).alignment = { horizontal: "center" };

    if (
      companyData !== null &&
      companyData !== undefined &&
      companyData.length > 0
    ) {
      companyData.forEach((company) => {
        // Add row for company details
        const row = worksheet.addRow([
          company?.company_name || "",
          company?.company_size || "",
          company?.description || "",
          company?.sort_order || "",
        ]);

        // Check if attached exists and is an array with files
        if (company?.attached?.length > 0) {
          // Join the URLs into a single string, separated by commas
          const fileLinks = company.attached.join(", ");
          worksheet.getCell(`E${row.number}`).value = fileLinks;
        } else {
          // If no files are attached, leave the cell empty or add a placeholder text
          worksheet.getCell(`E${row.number}`).value = "No files";
        }
      });
    }

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    const formattedDate = moment().format("MM-DD-YYYY_hh-mm-ss_A");

    // Save the Excel file
    saveAs(new Blob([buffer]), `CompanyData_${formattedDate}.xlsx`);
  } catch (error) {
    console.error("Error downloading Excel:", error);
  }
};

export { handleDownloadCompanyExcel };
