import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/frosted-farms.svg";
import TextField from "@mui/material/TextField";
import { FormHelperText } from "@mui/material";
import axios from "axios";
import { HttpService } from "../../service/HttpService";
import { useAuth } from "../../context/AuthProvider";
import { toast } from "react-toastify";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";

const Forgotpass = () => {
  const { setShowLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const [clicked, setClicked] = useState(false);
  const [email, setEmail] = useState("");
  const [textsuccess, setTextSuccess] = useState("");
  const [texterror, setTextError] = useState("");
  const [erroremail, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    // console.log(email);
    if (!email) {
      setEmailError("Enter your email");
      return;
    }
    setEmail("");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL + "user/forgot"}`,
        { email: email.trim() }
      );
      if (response && response.data && response.data.data) {
        localStorage.setItem(
          "Reset-Token",
          response.data.data.resetToken.token
        );
        localStorage.setItem("Email Id", email);
        setTextSuccess(response.data.data.message);
        toast.success(response.data.data.message, {
          position: "top-right",
        });
        setLoading(false);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      setTextError(errorMessage);
      setEmail(email);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="auth_screen_main_wrapper">
        <div className="row">
          <div className="auth-left col-sm-12 col-md-6">
            <div className="auth-img">
              {/* <div className="auth-info">
                <h1>Get Everything you want</h1>
                <p className="auth-disc">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div> */}
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="login_inner">
              <div className="logo_wrap">
                <img src={Logo} alt="frosted farms" />
              </div>
              <div className="form_wrapper">
                <div className="login_form">
                  <div className="login_form_inner">
                    <form>
                      <div className="title_wrapper">
                        <h1 className="login_title">Forgot Password ?</h1>
                        <div className="desc">
                          No worries, we will send you reset instruction{" "}
                        </div>
                      </div>

                      <div>
                        <h5
                          style={{
                            color: textsuccess
                              ? "green"
                              : texterror
                              ? "red"
                              : "inherit",
                          }}
                        >
                          {textsuccess || texterror || ""}{" "}
                          {/* Display success or error message */}
                        </h5>
                      </div>

                      <div className="form_field_wrapper">
                        <label
                          style={{ color: erroremail ? "red" : "inherit" }}
                        >
                          Email<span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          style={{
                            border: erroremail
                              ? "1px solid red"
                              : clicked
                              ? "1px solid blue"
                              : "inherit",
                          }}
                          id="input-with-icon-textfield"
                          placeholder="Enter your Email"
                          variant="outlined"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onClick={() => {
                            setClicked(true);
                            setEmailError("");
                            setTextError("");
                          }}
                          onBlur={() => setClicked(false)}
                        />
                        {erroremail && (
                          <FormHelperText id="component-error-text">
                            {erroremail}
                          </FormHelperText>
                        )}
                      </div>

                      <button
                        onClick={handleSubmit}
                        type="submit"
                        className="btn btn-primary"
                      >
                        {" "}
                        Reset Password
                      </button>
                      <div className="form_field_wrapper signuplink_block">
                        <Link to={"/login"} className="ato signupa">
                          Back to &nbsp;
                          <span className="signup_txt">Sign In</span>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgotpass;
