import React, { useState } from "react";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { validate_email, validate_password } from "./Validations";
import { FormHelperText } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { useAuth } from "../../../context/AuthProvider";
import { RxCross1 } from "react-icons/rx";

const Login = () => {
  const { showLoginPopup, setShowLoginPopup } = useAuth();
  //error displaying
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [clicked, setClicked] = useState(false);
  const [clickedd, setClickedd] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [erroremail, setEmailError] = useState("");
  const [errorpassword, setPasswordError] = useState("");

  const setValueOnLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
  };

  const setRoleWiseDatas = (user) => {
    if (user?.role_id === 1) {
      setValueOnLocalStorage("companyId", 0);
      setValueOnLocalStorage("locationId", 0);
    } else if (user?.role_id === 2) {
      setValueOnLocalStorage("companyId", user?.company_id);
      setValueOnLocalStorage("locationId", 0);
    } else if (user?.role_id === 3) {
      setValueOnLocalStorage("companyId", user?.company_id);
      setValueOnLocalStorage("locationId", user?.location_id);
    }
  };

  const handleLoginError = async (e) => {
    //Validations
    const emailError = validate_email(email);
    const passwordError = validate_password(password);

    if (emailError || passwordError) {
      setEmailError(emailError);
      setPasswordError(passwordError);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL + "user/login"}`,
        { email: email.trim(), password: password.trim() }
      );
      if (response?.status === 200) {
        toast.success("Logged In Successfully", {
          position: "top-right",
        });
        setShowLoginPopup(false);
        const userData = response?.data?.data?.user;
        localStorage.setItem("Login Token", userData.token);
        localStorage.setItem("email", email);
        localStorage.setItem(
          "user",
          JSON.stringify(response?.data?.data?.user)
        );
        setRoleWiseDatas(userData);
      }
    } catch (error) {
      console.error("Error while login", error);
      toast.error(error?.response?.data?.message, {
        position: "top-right",
      });
      if (error?.response?.status === 401) {
        setEmailError("Invalid Email Address");
      }
      if (error?.response?.status === 400) {
        setPasswordError("Invalid Password");
      }
    }
  };

  const handleClose = () => setShowLoginPopup(false);

  return (
    <>

        <Modal
          open={showLoginPopup}
          onClose={handleClose}
          aria-labelledby="login-modal-title"
          aria-describedby="login-modal-description"
        >
          <div className="modal_block login_modal_block">
            <div className="modal_header">
              <div className="modal_title_wrapper">
                <div className="title_wrapper">
                  <h1 className="login_title">Login Again</h1>
                  <div className="desc">
                    Your session has expired. Please log in again.
                  </div>
                </div>
              </div>
              <div className="cancel-btn-wrap">
                <RxCross1 onClick={handleClose} />
              </div>
            </div>
            <div className="modal_content">
              <div className="login_inner">
                <div className="form_wrapper">
                  <div className="login_form">
                    <div className="login_form_inner">
                      <form>
                        <div
                          className={`form_field_wrapper ${
                            erroremail ? "error" : ""
                          }`}
                        >
                          <label
                            style={{ color: erroremail ? "red" : "inherit" }}
                          >
                            Email<span style={{ color: "red" }}>*</span>
                          </label>
                          <TextField
                            style={{
                              border: erroremail
                                ? "1px solid red"
                                : clicked
                                ? "1px solid blue"
                                : "inherit",
                            }}
                            type="text"
                            placeholder="Enter your Email"
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onClick={() => {
                              setClicked(true);
                              setEmailError("");
                            }}
                            onBlur={() => setClicked(false)}
                          />
                          {erroremail && (
                            <FormHelperText id="component-error-text">
                              {erroremail}
                            </FormHelperText>
                          )}
                        </div>

                        <div className="form_field_wrapper">
                          <label
                            style={{
                              color: errorpassword ? "red" : "inherit",
                            }}
                          >
                            Password<span style={{ color: "red" }}>*</span>
                          </label>
                          <TextField
                            style={{
                              border: errorpassword
                                ? "1px solid red"
                                : clickedd
                                ? "1px solid blue"
                                : "inherit",
                            }}
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter your Password"
                            variant="outlined"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onClick={() => {
                              setClickedd(true);
                              setPasswordError("");
                            }}
                            onBlur={() => setClickedd(false)}
                          />
                          {errorpassword && (
                            <FormHelperText id="component-error-text">
                              {errorpassword}
                            </FormHelperText>
                          )}
                        </div>

                        <Button
                          onClick={handleLoginError}
                          type="button" // <-- Change this to type="button"
                          className="btn btn-primary"
                        >
                          {" "}
                          Sign In
                        </Button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
 
    </>
  );
};

export default Login;
