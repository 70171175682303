import ExcelJS from "exceljs";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { HttpService } from "../../service/HttpService";
import { displayDuration } from "../../Mixin/DisplayDuration";
import moment from "moment";

const handleDownloadExcel = async (setShowLoginPopup) => {
  const httpService = new HttpService(setShowLoginPopup);

  const fetchActivity = () => {
    return new Promise((resolve, reject) => {
      httpService
        .get(`activity/get_activity/`)
        .then((response) => {
          if (response?.data) {
            const rowData = response?.data?.data;
            resolve(rowData); // Resolve with the response data
          } else {
            reject(new Error("No data found")); // Reject if no data found
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          reject(error); // Reject with the error
        });
    });
  };

  try {
    const rowData = await fetchActivity();

    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet
    const worksheet = workbook.addWorksheet("Activities");

    // Add headers
    worksheet.addRow([
      "Name",
      "Duration",
      "Tasks",
      "Milestone",
      "Company",
      "Location",
      "Files",
      "Sequence",
      "Max employee",
      "Min employee"
    ]);
    worksheet.getRow(1).alignment = { horizontal: "center" };
    if (rowData !== null && rowData !== undefined && rowData.length > 0) {
      rowData.forEach((activity) => {
        worksheet.addRow([
          activity?.name || "",
          displayDuration(activity.days, activity.hours, activity.minutes) ||
            "",
          activity?.tasks?.length > 0
            ? activity.tasks.map((task) => task?.task_name || "--").join(", ")
            : "", // Formatting tasks
          activity?.milestone_name || "",
          activity?.company_name || "",
          activity?.location_name || "",
          activity?.attached?.length > 0 ? activity.attached.join(", ") : "",
          activity?.sort_order || "",
          activity?.max_employee || "",
          activity?.min_employee || "",
        ]);
      });
    }

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    const formattedDate = moment().format("MM-DD-YYYY_hh-mm-ss_A");

    // Save the Excel file
    saveAs(new Blob([buffer]), `Activities_${formattedDate}.xlsx`);
  } catch (error) {
    console.error("Error downloading Excel:", error);
  }
};

export { handleDownloadExcel };
