import React, { useEffect, useState } from "react";
import "../Dashboard/Dashboard.scss";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import DActivityIcon from "../../assets/icons/dashboard-activity.svg";
import DMilestoneIcon from "../../assets/icons/dashboard-milestone.svg";
import DRoomsIcon from "../../assets/icons/dashboard-rooms.svg";
import DSOPIcon from "../../assets/icons/dashboard-sop.svg";
import DTaskIcon from "../../assets/icons/dashboard-task.svg";
import { Modal } from "@mui/material";
import { IoIosArrowBack } from "react-icons/io";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";
import { HttpService } from "../../service/HttpService";
import dayjs from "dayjs";
import { useAuth } from "../../context/AuthProvider";
import { Encryption } from "../../Mixin/EncryptDecryptId";
import { useFilter } from "../../context/FilterProvider";
import CascadingDropdown from "../../components/CascadingDropdown";
import Select from "react-select";
import Schedule from "../../assets/icons/dashboard-schedule.svg";

const Card = ({ icon, title, count, change, changeColor, path }) => {
  const navigate = useNavigate();
  const handleNavigateModule = (path) => {
    navigate(`/${path}`);
  };
  return (
    // <div className="col-sm-4 col-md-3 col-xl-2 mt-4 mt-sm-0">
    <div
      className="card p-0"
      style={{ cursor: "pointer" }}
      onClick={() => path && handleNavigateModule(path)}
    >
      <div className="card-body counter-box">
        <div className="card-title-top">
          <p>
            <img src={icon} className="img-fluid" alt="Icon" /> {title}
          </p>
        </div>
        <div className="card-counting-num">
          <h1>{count}</h1>
          {/* <h4 className={changeColor}>{change}</h4> */}
        </div>
      </div>
    </div>
    // </div>
  );
};

const Dashboard = () => {
  const [openModel, setOpenModel] = useState(false);
  const [scheduleData, setScheduleData] = useState([]);
  const [task, setTask] = useState([]);
  const [graph, setGraph] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    dayjs().startOf("month").format("MM-DD-YYYY")
  );
  const [endDate, setEndDate] = useState(
    dayjs().endOf("month").format("MM-DD-YYYY")
  );
  const [startDateCopy, setStartDateCopy] = useState("");
  const [endDateCopy, setEndDateCopy] = useState("");
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const navigate = useNavigate();
  const [selectedScheduleValue, setSelectedScheduleValue] = useState("");
  const { company_id, location_id } = useFilter();
  const [dashboardData, setDashboardData] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedOption, setSelectedOption] = useState({
    value: "all",
    label: "Current Month",
  });
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedItem, setSelectedItem] = useState({
    value: "all",
    label: "All",
  });
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [scheduleConfliced, setScheduleConflicted] = useState(0);

  const handleChangeSchedule = (value) => {
    if (value === "Today") {
      setStartDate(dayjs().format("MM-DD-YYYY"));
      setEndDate("");
    } else if (value === "This_Week") {
      setStartDate(dayjs().startOf("week").format("MM-DD-YYYY"));
      setEndDate(dayjs().endOf("week").format("MM-DD-YYYY"));
    } else if (value === "Date_Range") {
      setOpenModel(true);
    } else if (value === "all") {
      setStartDate(dayjs().startOf("month").format("MM-DD-YYYY"));
      setEndDate(dayjs().endOf("month").format("MM-DD-YYYY"));
    }
  };

  const options = [
    { value: "all", label: "Current Month" },
    { value: "Today", label: "Today" },
    { value: "This_Week", label: "This Week" },
    { value: "Date_Range", label: "Date Range" },
  ];

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setSelectedScheduleValue(selectedOption.value);
    handleChangeSchedule(selectedOption.value);
  };

  const fetchEmpNRooms = () => {
    httpService
      .get("user-management/get_users")
      .then((response) => setEmployees(response?.data));
    httpService.get("rooms").then((response) => setRooms(response?.data?.data));
  };

  const fetchSchedule = () => {
    // if (!startDate && !endDate && !selectedFilter) {
    //   return false;
    // }

    setLoading(true);

    // Build query parameters dynamically
    const queryParams = [];
    if (startDate) queryParams.push(`start_date=${startDate}`);
    if (endDate) queryParams.push(`end_date=${endDate}`);
    if (selectedFilter && selectedFilter !== "all") {
      queryParams.push(selectedFilter);
    }

    // Construct the query string
    const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";

    httpService
      .get(`dashboard/schedules${queryString}`)
      .then((response) => {
        setScheduleData(response?.data?.schedules?.slice(0, 5));
        setTask(response?.data?.tasks?.slice(0, 5));
        setGraph(response?.data?.scheduleGraph?.slice(0, 10));
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const fetchTask = () => {
    setLoading(true);
    httpService
      .get("task/get_tasks")
      .then((response) => {
        // setTask(response?.data?.data.slice(0, 5));
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching tasks:", error);
      });
  };

  const fetchDashboardData = () => {
    setLoading(true);
    httpService
      .get("dashboard")
      .then((response) => {
        setDashboardData(response?.data);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching tasks:", error);
      });
  };

  const fetchCoflictedSchedule = () => {
    setLoading(true);
    httpService
      .get("dashboard/conflicted")
      .then((response) => {
        if (response?.data?.length > 0) {
          setScheduleConflicted(response?.data?.length);
        }
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching tasks:", error);
      });
  };

  useEffect(() => {
    fetchTask();
    fetchDashboardData();
    fetchEmpNRooms();
    fetchCoflictedSchedule();
    fetchSchedule();
  }, [showLoginPopup, company_id, location_id]);
  
  useEffect(() => {
    if (
      ["This_Week", "Today", "all"].includes(selectedScheduleValue) ||
      selectedFilter !== undefined
    ) {
      fetchSchedule();
    }
  }, [startDate, endDate, selectedFilter, selectedScheduleValue]);  

  const handleSubmit = () => {
    setStartDate(startDateCopy);
    setEndDate(endDateCopy);
    setOpenModel(false);
  };

  const handleReset = () => {
    setStartDate(dayjs().startOf("month").format("MM-DD-YYYY"));
    setEndDate(dayjs().endOf("month").format("MM-DD-YYYY"));
    setSelectedOption({
      value: "all",
      label: "All",
    });
    setSelectedFilter("");
    setSelectedScheduleValue("all");
    setSelectedCategory("");
    setSelectedItem("");
    setFilteredOptions([]);
    fetchSchedule();
  };

  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const { schedule_name, total_task, completed, pending } =
        payload[0].payload;

      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "10px",
            border: "1px solid #ccc",
          }}
        >
          <p className="label">{`${schedule_name}`}</p>
          <p>{`Tasks : ${total_task}`}</p>
          <p
            style={{ color: "rgb(0, 162, 114)" }}
          >{`Completed : ${completed}`}</p>
          <p style={{ color: "rgb(105 224 190)" }}>{`Pending : ${pending}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="dashboard">
        <div className="first">
          <div className="row card-row">
            <Card
              icon={DSOPIcon}
              title="Employees"
              count={dashboardData?.employees}
              change="+1.41%"
              changeColor="green"
              path="employee"
            />
            <Card
              icon={DMilestoneIcon}
              title="Milestones"
              count={dashboardData?.milestones}
              change="+1.41%"
              changeColor="green"
              path="milestone"
            />
            <Card
              icon={DActivityIcon}
              title="Activities"
              count={dashboardData?.activities}
              change="-1.41%"
              changeColor="red"
              path="activities"
            />
            <Card
              icon={DTaskIcon}
              title="Tasks"
              count={dashboardData?.tasks}
              change="+1.41%"
              changeColor="green"
              path="task"
            />
            <Card
              icon={DRoomsIcon}
              title="Rooms"
              count={dashboardData?.rooms}
              change="+1.41%"
              changeColor="green"
              path="room"
            />
            <Card
              icon={DSOPIcon}
              title="SOPs"
              count={dashboardData?.sops}
              change="+1.41%"
              changeColor="red"
              path="sops"
            />
            <Card
              icon={Schedule}
              title="Conflicted Schedules"
              count={scheduleConfliced}
              change="+1.41%"
              changeColor="red"
              path="schedule_conflict"
            />
          </div>
        </div>

        <div className="second">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="top_bar">
                    <div className="card_title">Schedule</div>

                    <div className="right_side">
                      <div
                        className={`form_field_wrapper ${
                          selectedCategory !== "" ? "fiter_emp_room" : ""
                        }`}
                      >
                        <label>Filter by Employee or Room</label>
                        <CascadingDropdown
                          employeeOptions={employees}
                          roomOptions={rooms}
                          onSelect={(e) => handleFilterSelect(e)}
                          setFilteredOptions={setFilteredOptions}
                          setSelectedItem={setSelectedItem}
                          setSelectedCategory={setSelectedCategory}
                          selectedCategory={selectedCategory}
                          selectedItem={selectedItem}
                          filteredOptions={filteredOptions}
                        />
                      </div>
                      <div className="form_field_wrapper fiter_range">
                        <label>Filter by Range</label>
                        <Select
                          options={options}
                          value={selectedOption}
                          onChange={(e) => handleChange(e)}
                          placeholder="Select Range"
                          isSearchable={false}
                        />
                      </div>
                      <div className="reset-button view-all-btn">
                        <label></label>
                        <button
                          onClick={() => {
                            handleReset();
                          }}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="bottom_bar">
                    <div className="row">
                      <div className="chart">
                        <div className="chart_inner">
                          <div className="bar_chart">
                            <ResponsiveContainer width="100%" height="100%">
                              <BarChart width={500} height={300} data={graph}>
                                <CartesianGrid strokeDasharray="2 2" />
                                <XAxis dataKey="schedule_name" />
                                <YAxis />
                                {graph?.length && (
                                  <Tooltip content={<CustomTooltip />} />
                                )}
                                <Legend colorInterpolation={"#666666"} />
                                <Bar
                                  barSize={90}
                                  dataKey="completed"
                                  name="Completed Task"
                                  stackId="a"
                                  fill="#00A272"
                                  color="#666666"
                                />
                                <Bar
                                  barSize={90}
                                  dataKey="pending"
                                  name="Pending Task"
                                  stackId="a"
                                  fill="#E6F6F1"
                                  color="#666666"
                                />
                              </BarChart>
                            </ResponsiveContainer>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="third">
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="top_bar">
                    <div className="card_title">Schedules</div>
                  </div>

                  <div className="table_wrapper">
                    <div className="table_inner">
                      <TableContainer component={Paper}>
                        <Table aria-label="simple table" className="table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Schedule</TableCell>
                              <TableCell align="center">Start Date</TableCell>
                              <TableCell align="right">End Date</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {scheduleData?.length > 0 &&
                              scheduleData?.map((scheduleData, index) => {
                                const urlEncodedId = Encryption(
                                  scheduleData?.id
                                );
                                return (
                                  <TableRow
                                    key={index}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                      "&:hover": {
                                        backgroundColor: "#e6f6f1  !important", // Change this to your desired hover color
                                      },
                                      "& a": {
                                        display: "flex",
                                        width: "100%",
                                        textDecoration: "none",
                                        color: "inherit",
                                      },
                                    }}
                                  >
                                    <Link
                                      to={`/schedule/view/${urlEncodedId}`}
                                      state={{ from: "dashboard" }}
                                      style={{
                                        display: "contents",
                                        textDecoration: "none",
                                      }}
                                    >
                                      <TableCell component="th" scope="row">
                                        {scheduleData?.name}
                                      </TableCell>
                                      <TableCell align="center">
                                        {dayjs
                                          .utc(scheduleData?.start_date)
                                          .format("MMM/DD/YYYY hh:mm a") ||
                                          "N/A"}
                                      </TableCell>
                                      <TableCell align="right">
                                        {dayjs
                                          .utc(scheduleData?.end_date)
                                          .format("MMM/DD/YYYY hh:mm a") ||
                                          "N/A"}
                                      </TableCell>
                                    </Link>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <div className="view-all-btn">
                        <button onClick={() => navigate("/schedule")}>
                          View All
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="top_bar">
                    <div className="card_title">Tasks</div>
                    {/* <div className="form_field_wrapper">
                      <select
                        name="report"
                        id="report"
                        onChange={(e) => handleChangeTask(e?.target?.value)}
                      >
                        <option value="Today">Today</option>
                        <option value="This_Week">This Week</option>
                        <option value="Date_Range">Date Range</option>
                      </select>
                    </div> */}
                  </div>

                  <div className="table_wrapper">
                    <div className="table_inner">
                      <TableContainer component={Paper}>
                        <Table aria-label="simple table" className="table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Tasks</TableCell>
                              <TableCell align="center">
                                Start Date & Time
                              </TableCell>
                              <TableCell align="right">
                                End Date & Time
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {task?.length > 0 &&
                              task?.map((row, index) => {
                                const urlEncodedTaskId = Encryption(row?.id);
                                const task_id = Encryption(row?.task_id);
                                return (
                                  <TableRow
                                    hover
                                    key={index}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                      "&:hover": {
                                        backgroundColor: "#e6f6f1  !important", // Change this to your desired hover color
                                      },
                                      "& a": {
                                        display: "flex",
                                        width: "100%",
                                        textDecoration: "none",
                                        color: "inherit",
                                      },
                                    }}
                                  >
                                    <Link
                                      to={`/schedule/taskview/${urlEncodedTaskId}`}
                                      state={{
                                        from: "dashboard",
                                        task_id: task_id,
                                      }}
                                      style={{
                                        display: "contents",
                                        textDecoration: "none",
                                      }}
                                    >
                                      <TableCell component="th" scope="row">
                                        {row?.task_name}
                                      </TableCell>
                                      <TableCell align="center">
                                        {dayjs
                                          .utc(row?.start_date)
                                          .format("MMM/DD/YYYY hh:mm a") ||
                                          "N/A"}
                                      </TableCell>
                                      <TableCell align="right">
                                        {dayjs
                                          .utc(row?.end_date)
                                          .format("MMM/DD/YYYY hh:mm a") ||
                                          "N/A"}
                                      </TableCell>
                                    </Link>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <div className="view-all-btn">
                        <button onClick={() => navigate("/task")}>
                          View All
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {openModel === true && (
          <Modal className="dashboard" open={openModel}>
            <div className="modal_block">
              <div className="modal_header">
                <div className="cancel-btn-wrap">
                  <IoIosArrowBack
                    onClick={() => {
                      setOpenModel(false);
                    }}
                  />
                </div>
                <div className="modal_title_wrapper">
                  <h2 className="modal_title">Date Range</h2>
                </div>
                <div className="cancel-btn-wrap"></div>
              </div>
              <div className="modal_content">
                <div className="modal_content_inner">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form_field_wrapper">
                        <label>
                          From Date
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            name="start_date"
                            format="MM-DD-YYYY"
                            onChange={(newStartDate) => {
                              setStartDateCopy(
                                dayjs(newStartDate).format("MM-DD-YYYY")
                              );
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form_field_wrapper">
                        <label>
                          To Date
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            name="end_date"
                            format="MM-DD-YYYY"
                            onChange={(newEndDate) => {
                              setEndDateCopy(
                                dayjs(newEndDate).format("MM-DD-YYYY")
                              );
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal_footer">
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleSubmit(e);
                    setOpenModel(false);
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
      {/* <div>
        <h1 style={{ color: "blue", fontFamily: "wf_title" }}>Coming soon!!</h1>
      </div> */}
    </>
  );
};

export default Dashboard;
