import ExcelJS from "exceljs";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { HttpService } from "../service/HttpService";

const handleDownloadExcel = async (id, setShowLoginPopup) => {
  const httpService = new HttpService(setShowLoginPopup);

  const fetchScheduleById = (id) => {
    return new Promise((resolve, reject) => {
      httpService
        .get(`schedule/get_schedule/${id}`)
        .then((response) => {
          if (response?.data) {
            const rowData = response?.data;
            resolve(rowData); // Resolve with the response data
          } else {
            reject(new Error("No data found")); // Reject if no data found
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          reject(error); // Reject with the error
        });
    });
  };

  try {
    const rowData = await fetchScheduleById(id);

    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet
    const worksheet = workbook.addWorksheet("Schedule");

    // Add headers
    worksheet.addRow([
      "Schedule Name",
      "Plant Name",
      "Room Name",
      "Start Date",
      "End Date",
    ]);

    // Add data
    const selectedData = [
      [
        rowData?.name,
        rowData?.plant_name || "",
        rowData?.room_name || "",
        rowData?.start_date
          ? dayjs(rowData?.start_date).format("MMM/DD/YYYY")
          : "",
        rowData?.end_date ? dayjs(rowData?.end_date).format("MMM/DD/YYYY") : "",
      ],
    ];

    selectedData.forEach((row) => {
      worksheet.addRow(row);
    });

    // Add a centered heading "Schedule"
    worksheet.mergeCells("A5:F5"); // Merge cells from A5 to F5
    const scheduleHeadingRow = worksheet.getRow(5); // Get the merged cell
    scheduleHeadingRow.getCell(1).value = "Schedule"; // Set the value
    scheduleHeadingRow.getCell(1).alignment = { horizontal: "center" }; // Center align the text

    // Add an empty row
    worksheet.addRow([]);

    // Add subheadings for Schedules
    worksheet.addRow([
      "Milestones",
      "Activities",
      "Tasks",
      "Employees",
      "Status",
      "On-Going Task",
      "Start Date",
      "End Date",
      "Duration",
      "Week Days",
      "Frequency"
    ]);

    // Add data for each schedule
    if (
      rowData?.schedules !== null &&
      rowData?.schedules !== undefined &&
      rowData?.schedules.length > 0
    ) {
      rowData?.schedules.forEach((schedule) => {
        // Determine the status
        const startDate = new Date(schedule?.start_date);
        const endDate = new Date(schedule?.end_date);
        const jobCompleted = schedule?.job_completed;

        let status;
        if (jobCompleted === 0) {
          const now = new Date();
          if (startDate <= now && endDate > now) {
            status = "Ongoing";
          } else if (startDate > now) {
            status = "To Do";
          } else if (endDate < now) {
            status = "Pending";
          } else {
            status = "Unknown";
          }
        } else {
          switch (jobCompleted) {
            case 1:
              status = "Done";
              break;
            case 2:
              status = "Approved";
              break;
            case 3:
              status = "Rejected";
              break;
            default:
              status = "Unknown";
          }
        }
        worksheet.addRow([
          schedule?.milestone_name || "",
          schedule?.activity_name || "",
          schedule?.task_name || "",
          (schedule?.selected_employees?.length > 0 &&
            schedule?.selected_employees?.map((employee) => employee.name)
              .join(", ")) ||
            "",
          status,
          schedule.ongoing ? "On Going" : "-",
          schedule?.start_date
            ? dayjs(schedule?.start_date).format("MMM/DD/YYYY hh:mm a")
            : "",
          schedule?.end_date
            ? dayjs(schedule?.end_date).format("MMM/DD/YYYY hh:mm a")
            : "",
            schedule?.cron_duration,
            schedule?.cron_days && schedule?.cron_days.length > 0 ? schedule?.cron_days.join(", ") : "-",
            schedule?.cron_frequency,
        ]);
      });
    }

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Save the Excel file
    saveAs(new Blob([buffer]), `schedule_${rowData?.name || "Demo"}.xlsx`);
  } catch (error) {
    console.error("Error downloading Excel:", error);
  }
};

export { handleDownloadExcel };
